import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";

const philosophyPage = () => {
    return (
      <Layout>
        <Seo
        title="Our Philosophy"
        description="Why we do what we do."/>
        <div className="flex flex-col lg:flex-row justify-center items-center m-10">
          <div className="lg:w-1/2 h-1/2">
            <StaticImage className="lg:fixed lg:w-1/2 left-0 top-0" src="../images/IMG_20200522_163913.jpeg"/>
            
          </div>
        <div className="lg:w-1/2">
        <h1 className="lg:w-1/2 text-left text-4xl lg:text-6xl font-semibold mx-5 lg:mx-0 pb-10 lg:py-40">our <br/>philosophy</h1>
          <p className="mx-5 lg:mx-0 lg:w-2/3">Hey, we’re Philipp and Georg.  
          <br /><br />
          We are filmmakers, tech enthusiasts, makers, meme lords and nerds. <br />
          We met at uni when we were studying Motion Pictures at the University of Applied Sciences Darmstadt. <br />
          As we were working on our films there was always some tech missing. <br />
          So we decided to take it into our own hands and produce our own equipment. <br />
          <br /><br />
          There are so many great people and companies already producing fascinating film tech, but we still wanted to do something different. <br />
          Sustainability became dangerously important over the last few years, as human waste destroyed landscapes, habitats and more. <br />
          But this topic hasn't really reached the film tech industry yet. <br /> And we want to change that. <br /><br />
          Our aim is to help filmmakers with innovative, easy to use tools without the burden of hurting the environment. We use recycled, renewable and sustainable
          materials, reduce the overall necessary energy and resources and ship co2-neutral. <br /><br />
          Our products are handmade in our small shop in Dieburg. Resources like wood are sourced in a radius of 50km to keep transportation and delivery chains small. <br />
          The goal is to manufacture eco-friendly, without compromises in our product's quality and features.
          <br /><br />
          As we are filmmakers ourselves, we know what issues exist on set, and we try to find solutions for them. <br />
          Time-efficiency, sturdiness and ease of use are all things we strive for. Film tech has to be reliable and convenient, but also quickly usable by people who never have used it before. <br />
          We love having long discussions with other professionals and are always open for feedback to try and find solutions for existing problems. <br /><br />
          Last, but definitely not least, we want new and upcoming filmmakers without a big budget to have access to professional grade equipment. <br />
          We started out with very minimal equipment and know very well how hard it is to realize creative ideas with it. <br />
          Pricing on professional equipment is oftentimes off the charts for many aspiring creatives, while cheap alternatives don't satisfy their needs. <br /> We want to bridge the gap and offer our products at fair prices while making our products as capable as possible. <br /><br />
          We want no less than to democratize the film tech market.
          </p>
        </div>  </div>
      </Layout>
  )
}

export default philosophyPage